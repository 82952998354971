import * as React from 'react'

import { Link, graphql } from 'gatsby'

import Layout from '../components/core/Layout'

const TagTemplate = ({ pageContext, data }: any) => {
  const { tag } = pageContext
  const { nodes, totalCount } = data.allMdx
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`

  return (
    <Layout>
      <div className="container row margin-10-t margin-10-b">
        <div className="col-xs-12 pad-5-lr margin-5-b">
          <h1 className="is-background-blue-text">{tagHeader}</h1>
        </div>
        <div className="col-xs-12 pad-5-lr margin-5-b">
          {nodes.map((node: any) => (
            <p>{node.frontmatter.title}</p>
          ))}
        </div>
        <div className="pad-5-lr">
          <Link to="/tags">
            <h2 className="is-background-blue-text active underline">
              All tags
            </h2>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query ($tag: String) {
    allMdx(limit: 2000, filter: { frontmatter: { tags: { in: [$tag] } } }) {
      totalCount
      nodes {
        slug
        frontmatter {
          title
          tags
        }
      }
    }
  }
`
